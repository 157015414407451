export default {
  data() {
    return {
      typeOfInput: 'password',
    };
  },
  methods: {
    changeTypeOfInput() {
      if (this.typeOfInput === 'password') {
        this.typeOfInput = 'text';
      } else {
        this.typeOfInput = 'password';
      }
    },
  },
  computed: {
    changeIcon() {
      if (this.typeOfInput === 'password') {
        return 'ion ion-eye-off-outline';
      }
      return 'ion ion-eye-outline';
    },
  },
};
