<template>
  <div class="request-demo">
    <div class="request-demo-card">
      <div class="request-demo-card-header">
        <h2 class="request-demo-card-header__title" v-html="$t('requestDemo.title')"></h2>
        <button class="request-demo-card-header__button" @click="$emit('closeModalRequest')">
          X
        </button>
      </div>
      <div class="request-demo-form" :key="remountForm">
        <div class="request-demo-card__content">
          <div class="request-demo-card-column1">
            <div class="request-demo-information">
              <label for="full-name" class="request-demo-information__label">
                {{ $t("requestDemo.fullName") }}
              </label>
              <input
                :class="error.full_name.empty === true ? 'missing-field' : ''"
                type="text"
                class="request-demo-information__input"
                name="full-name"
                v-model.trim="form.full_name"
              />
              <span class="error-message">{{ error.full_name.message }}</span>
            </div>
            <div class="request-demo-information">
              <label for="email" class="request-demo-information__label"
                >{{ $t("requestDemo.email") }}
              </label>
              <input
                :class="error.email.empty ? 'missing-field' : ''"
                type="email"
                class="request-demo-information__input"
                name="email"
                v-model.trim="form.email"
              />
              <span class="error-message">{{ error.email.message }}</span>
            </div>
            <div class="request-demo-information">
              <label for="phone-number" class="request-demo-information__label">
                {{ $t("requestDemo.phoneNumber") }}</label
              >
              <input
                :class="error.phone_number.empty ? 'missing-field' : ''"
                type="tel"
                class="request-demo-information__input"
                name="phone-number"
                v-model.trim="form.phone_number"
              />
              <span class="error-message">{{ error.phone_number.message }}</span>
            </div>
          </div>
          <div class="request-demo-card-column2">
            <div class="request-demo-information">
              <label class="request-demo-information__label" for="company-name">{{
                $t("requestDemo.companyName")
              }}</label>
              <input
                :class="error.company_name.empty ? 'missing-field' : ''"
                type="text"
                class="request-demo-information__input"
                name="company-name"
                v-model.trim="form.company_name"
              />
              <span class="error-message">{{ error.company_name.message }}</span>
            </div>
            <div class="request-demo-information">
              <label class="request-demo-information__label" for="website">
                {{ $t("requestDemo.website") }}
              </label>
              <input
                :class="error.website.empty ? 'missing-field' : ''"
                type="text"
                class="request-demo-information__input"
                name="website"
                v-model.trim="form.website"
              />
              <span class="error-message">{{ error.website.message }}</span>
            </div>
            <div class="request-demo-information">
              <label for="countryCode" class="request-demo-information__label">{{
                $t("requestDemo.countryCode")
              }}</label>
              <div class="request-demo-information__input-dropdown">
                <Dropdown
                  v-model="chosenCountry"
                  :class="error.country.empty ? 'missing-field' : ''"
                  class="request-demo-card-dropdown"
                  :options="countries"
                  :filter="true"
                  :showClear="true"
                  optionLabel="name"
                  @change="changeCountry($event.value)"
                >
                  <template #value="slotProps">
                    <div class="country-item country-item-value" v-if="slotProps.value">
                      <div>{{ slotProps.value.name }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div class="country-item">
                      <div>{{ slotProps.option.name }}</div>
                    </div>
                  </template>
                </Dropdown>
                <span class="error-message">{{ error.country.message }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="request-demo-card-bottom" name="action" @click="sendInfo">
          <button
            v-html="$t('requestDemo.title')"
            class="request-demo-card-bottom__button"
            v-loading="loading"
          ></button>
        </div>
      </div>
    </div>
    <b-modal
      id="v-b-modal.modal-multi-2"
      hide-header
      hide-footer
      size="lg"
      no-close-on-backdrop
      centered
    >
      <TermsAndConditions
        @closeModalTerms="closeModalTerms"
        @acceptTerms="acceptTerms"
        @declineTerms="declineTerms"
      />
    </b-modal>
  </div>
</template>

<script>
import * as yup from "yup";
import Dropdown from "primevue/dropdown";
import TermsAndConditions from "@/views/TermsAndConditions/Index.vue";
import getCountriesList from "../../utils/countriesList";
import AcceptTerms from "../../Extend/AcceptTerms";

export const requestDemoSchema = yup.object().shape({
  company_name: yup.string().required("This field cannot be empty"),
  website: yup.string(),
  phone_number: yup.string().required("This field cannot be empty"),
  country: yup.string().required("Please select a country"),
  full_name: yup.string().required("This field cannot be empty"),
  email: yup.string().email("Must be a valid email").required("Must be a valid email"),
});

export default {
  name: "RequestDemo",
  emits: ["closeModalRequest"],
  mixins: [AcceptTerms],
  components: {
    Dropdown,
    TermsAndConditions,
  },
  data() {
    return {
      checkedTerms: false,
      form: {
        company_name: "",
        phone_number: "",
        full_name: "",
        website: "",
        country: "",
        email: "",
      },
      error: {},
      chosenCountry: null,
      countries: [],
      loading: false,
      remountForm: 0,
    };
  },
  created() {
    this.setCountriesList();
    this.buildErrorsObject();
  },
  methods: {
    setCountriesList() {
      this.countries = getCountriesList();
    },
    buildErrorsObject() {
      Object.keys(this.form).forEach((key) => {
        this.error[key] = {
          message: "",
          empty: false,
        };
      });
    },
    async sendInfo() {
      this.loading = true;
      const isValid = await this.validateFields();
      if (isValid) {
        await this.$store.dispatch("requestDemo/sendRequestDemo", { body: this.form });
        this.$emit("closeModalRequest");
        this.swal({
          title: this.$t("Good job!"),
          text: this.$t("A consultant will contact you for a demonstration of Portal."),
          icon: "success",
          confirmButtonText: this.$t("requestDemo.successButton"),
        });
      }
      this.loading = false;
    },
    changeCountry(country) {
      this.form.country = country?.name || "";
    },
    async validateFields() {
      try {
        const isValid = await requestDemoSchema.validate(this.form, {
          abortEarly: false,
        });
        this.buildErrorsObject();
        this.remountForm += 1;
        return isValid;
      } catch (error) {
        this.buildErrorsObject();
        error.inner.forEach((element) => {
          this.error[element.path].empty = true;
          this.error[element.path].message = element.message;
        });
        this.remountForm += 1;
        return false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.request-demo-card {
  width: 100%;
  font-family: $font-family-portal;
  background-color: $color-background-request;
  &__content {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
.request-demo-card-header {
  width: 100%;
  @extend %flex-center;
  font-family: $font-family-portal;
  color: $color-white;
  margin: 20px 0px 40px 0px;
  &__title {
    width: 100%;
    @extend %flex-center-center;
  }
  &__button {
    border: none;
    background: $color-inherit;
    border-radius: 100%;
    padding: 4px 10px;
    margin: 0px 15px 8px 0px;
    color: $color-white;
    @extend %flex-end-center;
    background-color: $color-primary-company;
    &:hover {
      background-color: #00ff00;
    }
  }
}
.request-demo-card-column1,
.request-demo-card-column2 {
  width: 100%;
  @extend %flex-col-center-center;
}

.request-demo-information {
  position: relative;
  width: 80%;
  &__label {
    @include label-input-information;
    @include font-small-standart-text;
    color: $color-white;
  }
  &__input {
    @include input-information;
    @extend %flex-center;
    width: 100%;
    height: 41px;
    padding: 10px 0px 10px 10px;
    box-shadow: none;
    border: none;
    font-size: $font-size-input;
  }
}

.request-demo-card-bottom {
  margin-top: 40px;
  &__button {
    @include primary-button($heightSize: 50px);
    @extend %flex-center-center;
    font-family: $font-family-portal;
    padding: 5px 90px;
    border-radius: 10px;
    margin-bottom: 20px;
    width: 50%;
  }
}

.missing-field {
  background: $missing-fields;
}

.error-message {
  position: absolute;
  font-size: 12px;
  bottom: -10px;
  right: 0px;
  color: $missing-fields;
}
///****Responsive styles****\\\\

@media (max-width: 990px) {
  .request-demo-card {
    &__content {
      display: flex;
      flex-direction: column;
    }
    .request-demo-card-header {
      margin: 10px 0px 10px 0px;
      &__button {
        display: flex;
        justify-content: center;
      }
    }
  }
}
///****Modified primeVue styles-dropdown****\\\\
::v-deep {
  .el-loading-mask {
    border-radius: 10px;
  }
  .p-dropdown {
    width: 100%;
    border: none;
    margin: 0px 5px 10px 0px;
    border-radius: 15px;
    height: 2.563rem;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: none;
    color: $color-white;
  }
  .p-dropdown-trigger {
    color: $color-primary-company;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border-color: none;
    background-color: $color-white;
  }
  .p-dropdown .p-dropdown-label.p-placeholder {
    font-family: $font-family-portal;
    color: $color-primary-company;
    font-size: $font-size-input;
  }
  .p-dropdown.p-dropdown-clearable .p-dropdown-label {
    font-family: $font-family-portal;
    color: $color-primary-company;
  }
  .p-inputtext {
    font-size: $font-size-input;
    padding-top: 6px;
  }
}

///****Modified primeVue styles-Checkbox****\\\\

::v-deep .p-inputtext:enabled:focus {
  box-shadow: none;
  border-color: none;
  border: none;
}
</style>
