export default {
  data() {
    return {
      showModalTerms: false,
      checkedTerms: [],
    };
  },
  methods: {
    openModalTerms() {
      this.showModalTerms = true;
    },
    closeModalTerms() {
      this.showModalTerms = false;
      this.$bvModal.hide('v-b-modal.modal-multi-2');
    },
    acceptTerms() {
      this.showModalTerms = false;
      this.checkedTerms = true;
    },
    declineTerms() {
      this.showModalTerms = false;
      this.checkedTerms = false;
    },
  },
};
