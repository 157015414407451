export default {
  methods: {
    async auth() {
      this.errors = "Successfull";
      this.messageError = false;
      const response = await this.$store.dispatch("login/signIn", this.credentials);
      if (!response) {
        return;
      }
      const tokenInfo = this.$store.getters["login/getTokenInfo"];
      if (tokenInfo.is_branded) {
        this.changeView("MyLoads");
        return;
      }
      if (response) {
        this.changeView("Dashboard");
      }
    },
  },
};
