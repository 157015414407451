<template>
  <div class="login-content">
    <div class="overlay"></div>
    <div class="login-content__form">
      <img class="mt-5 ml-2" src="../../assets/images/GLT-logo.png" alt="GLT LOGO" />
      <div class="login-content__inputs">
        <div class="login-submit">
          <div class="login-submit-email">
            <label class="login-submit-email__label" for="email">{{ $t("login.email") }} </label>
          </div>
          <input
            type="text"
            v-model.trim="credentials.email"
            class="login-submit__input"
            name="email"
            @keydown.enter="checkForm"
          />
        </div>
        <div class="login-submit">
          <label class="login-submit__label" form="password">{{ $t("login.password") }} </label>
          <div class="login-submit__input">
            <input
              :type="typeOfInput"
              v-model="credentials.password"
              class="input-password"
              @keydown.enter="checkForm"
            />
            <button class="button-password" type="button" @click="changeTypeOfInput">
              <i :class="changeIcon"></i>
            </button>
          </div>
          <span class="login-submit__message-error" v-show="messageError">
            {{ $t("login.messageError") }}
          </span>
        </div>
        <div class="login-button-container">
          <b-overlay :show="loadingLogin" rounded="sm">
            <button
              class="login-button-container__button"
              type="submit"
              v-html="$t('login.signIn')"
              @click="checkForm"
            ></button>
          </b-overlay>
        </div>
        <button
          class="request-demo-button"
          v-b-modal="'v-b-modal.modal-multi-1'"
          @click="openModalRequest"
          v-html="$t('login.requestDemo')"
        ></button>
        <div class="question-container">
          <p>{{ $t("login.dontAccount") }}&nbsp;</p>
          <span
            @click="changeView('CreateAccount')"
            class="question-container__question"
            v-html="$t('login.getStarted')"
          >
          </span>
        </div>
        <div class="question-container">
          <p>{{ $t("login.forgotPassword") }}</p>
          <span
            @click="changeView('RecoverPassword')"
            class="question-container__question"
            v-html="$t('login.restoreIt')"
          >
          </span>
        </div>
        <div class="question-container">
          <p>Are you a drayage customer?</p>
          <a
            href="https://mygltdrayage.com/users/login"
            target="_blank"
            class="question-container__question"
            v-html="'Click here'"
          >
          </a>
        </div>
      </div>
    </div>
    <div class="brand-container">
      <span class="brand-container__text">GLT Logistics ® - 2022</span>
    </div>
    <b-modal
      id="v-b-modal.modal-multi-1"
      hide-header
      hide-footer
      size="lg"
      no-close-on-backdrop
      centered
    >
      <RequestDemo :openModalRequest="openModalRequest" @closeModalRequest="closeModalRequest" />
    </b-modal>
  </div>
</template>

<script>
// import { useAccountSettingsTheme } from "@/utils/Themes";
import RequestDemo from "@/views/RequestDemo/Index.vue";
import showPassword from "../../Extend/ShowPassword";
import Login from "../../Extend/Login";

export default {
  name: "Login",
  mixins: [showPassword, Login],
  components: {
    RequestDemo,
  },
  data() {
    return {
      credentials: {
        email: null,
        password: null,
      },
      messageError: false,
      errors: null,
      showModalRequest: false,
    };
  },
  computed: {
    loadingLogin: {
      get() {
        return this.$store.getters["login/getLoadingLogin"];
      },
      set(value) {
        this.$store.commit("login/setLoadingLogin", value);
      },
    },
  },
  methods: {
    async checkForm() {
      if (!this.credentials.email || !this.credentials.password) {
        this.errors = "Something is wrong!";
        this.messageError = true;
        return;
      }
      this.messageError = false;
      this.loadingLogin = true;
      await this.auth();
    },

    openModalRequest() {
      this.showModalRequest = true;
    },
    closeModalRequest() {
      this.showModalRequest = false;
      this.$bvModal.hide("v-b-modal.modal-multi-1");
    },
    changeView(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.login-content {
  background: url("../../assets/images/home-background.jpg");
  @include background-image(0%, 20%);
  @extend %flex-col-center-center;
  justify-content: flex-start;
  font-family: $font-family-portal;
  &__inputs {
    width: 60%;
    margin-bottom: 40px;
    @media (max-width: 490px) {
      width: 80%;
    }
  }
  &__form {
    @include card-styles;
    @extend %flex-col-center-center;
    margin-top: 7%;
    z-index: 1;
    img {
      margin-bottom: 4%;
      width: 150px;
      height: auto;
    }
    p {
      color: $color-primary-company;
      @extend %flex-content-center;
    }
  }
}

.overlay {
  @include background-image-overlay($color-primary-company, 0.85);
}

.brand-container {
  @include brand-quote($color-white);
}

.login-button-container {
  margin: 25px 0px 20px 0px;
  &__button {
    @include primary-button(40px);
    background-color: $color-primary-company;
    margin: 0.625rem 0 1.25rem 0;
    @include font-button-text;
    color: white;
    &:hover {
      background-color: $color-primary-company-hover;
    }
  }
}
.request-demo-button {
  @include green-button(40px);
  margin-bottom: 1.25rem;
  @include font-button-text;
}
.ion {
  @include icon-styles;
}
.login-submit {
  width: 100%;
  position: relative;
  &__label {
    @include label-input-information;
    justify-content: center;
  }
  &__input {
    @include input-information;
    text-align: center;
    width: 100%;
  }
  &__message-error {
    color: red;
    font-weight: bold;
    margin: 12px auto;
    font-size: 11px;
    position: absolute;
    left: 0;
    width: 100%;
    top: 67px;
    font-size: 0.78rem;
    i {
      color: red;
    }
  }
}
.login-submit-email {
  display: grid;
  grid-template-columns: 55% 45%;
  align-items: center;
  &__label {
    @include label-input-information;
    margin: 5px 0px;
    display: flex;
    justify-content: flex-end;
  }
  .ion-alert-circle-outline {
    color: red;
    // color: $color-secondary-company;
  }
}
.input-password {
  @include input-password;
  line-height: 0;
  margin: none;
  padding: 0px 10px;
  margin-left: 37px;
  text-align: center;
}
.button-password {
  @include icon-input;
  @extend %flex-center-center;
  margin-right: 0.313rem;
}

.question-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1.25rem;
  @include font-small-standart-text;
  & p {
    margin: 0px;
  }
  &__question {
    @extend %flex-content-center;
    cursor: pointer;
    color: #00ff00;
    margin-left: 5px;
    font-weight: bold;
  }
  @media (max-width: 490px) {
    display: unset;
  }
}

@media (max-width: 550px) {
  .login-submit-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__message-error {
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
///****Modal Request Demo styles****\\\\
::v-deep .modal-content {
  background-color: $color-background-request;
  border-radius: 15px;
  border: none;
}

::v-deep .bg-light {
  background-color: #ffffff !important;
}
</style>
