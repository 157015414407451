const termsAndConditions = ` 
<p>
These Terms and Conditions of Use ("Agreement") is a legal agreement
between you (the user, also referenced as “Client” hereinafter, which
shall include all individuals and entities on behalf of which the site
is utilized) and GLT Transportation Group, LLC (hereinafter referred to
as "Website Owner"), the owner and developer of goglt.com. By
registering for any service provided on goglt.com you become a client
("Client") and you agree to be bound by all of the terms (the "Terms")
set forth in this Agreement as long as you remain a Client. IF YOU DO
NOT AGREE TO THE TERMS, PLEASE DO NOT REGISTER FOR ANY goglt.com
SERVICE. The Terms are subject to change at any time, effective upon
notice to you.
</p>
<p>
BY CLICKING THE "I AGREE" BUTTON BELOW, YOU AGREE THAT YOU HAVE READ,
UNDERSTAND AND AGREE TO BE BOUND BY THE TERMS. GOGLT.COM RESERVES THE
RIGHT, WITH OR WITHOUT NOTICE, TO MAKE CHANGES TO THE TERMS AT WEBSITE
OWNER’S DISCRETION. YOUR CONTINUED USE OF ANY PART OF THIS WEBSITE OR
ANY SERVICE CONSTITUTES YOUR ACCEPTANCE OF SUCH CHANGES TO THIS
AGREEMENT. YOU SHOULD REVIEW THIS AGREEMENT PERIODICALLY TO DETERMINE IF
ANY CHANGES HAVE BEEN MADE. THE MOST CURRENT VERSION OF THE TERMS, WHICH
SUPERSEDES ALL PREVIOUS VERSIONS, CAN BE REVIEWED BY GOING TO
<a href="GOGLT.COM">GOGLT.COM</a>. GOGLT.COM RESERV
</p>
<ol type="1">
<li>
  <p>
    <span style="font-weight: bold">
      Limitations of Liability.
    </span>
    Client agrees that goglt.com, its subsidiaries, affiliates,
    licensors, licensees, service providers, employees, agents,
    officers, and directors WILL NOT BE LIABLE FOR ANY INCIDENTAL,
    DIRECT, INDIRECT, PUNITIVE, ACTUAL, CONSEQUENTIAL, SPECIAL,
    EXEMPLARY, OR OTHER DAMAGES, INCLUDING LOSS OF REVENUE OR INCOME,
    PAIN AND SUFFERING, EMOTIONAL DISTRESS, OR SIMILAR DAMAGES, even if
    goglt.com has been advised of the possibility of such damages, such
    damages were reasonably foreseeable or goglt.com was grossly
    negligent. In no event will the collective liability of goglt.com
    and its subsidiaries, affiliates, licensors, service providers,
    content providers, employees, agents, officers, and directors,
    regardless of the form of action (whether in contract, tort, or
    otherwise), exceed the fees paid by client to goglt.com for the
    applicable service out of which such liability arose. (In any
    jurisdiction where some portion of the above limitation is not
    permissible under applicable law, the liability of goglt.com, its
    Subsidiaries, Affiliates, Licensors, Licensees, Service Providers,
    Employees, Agents, Officers, and Directors will be limited to the
    greatest extent permitted by law in said jurisdiction.)
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Indemnification.
    </span>
    Client agrees to indemnify and hold goglt.com, its subsidiaries,
    affiliates, licensors, service providers, employees, agents,
    officers, directors, and contractors (the “indemnified parties”)
    harmless from any breach of the terms of this agreement by client.
    Client agrees that the indemnified parties will have no liability in
    connection with any such breach or unauthorized use of goglt.com or
    any information obtained from or through goglt.com, and client
    agrees to indemnify any and all resulting loss, damages, judgments,
    awards, costs, expenses, and attorneys' fees of the indemnified
    parties in connection therewith. Client will also indemnify and hold
    the indemnified parties harmless from and against any claims brought
    by third parties arising out of client’s use of goglt.com and any
    information accessed from this website.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Responsibility for Use of Website.
    </span>
    You understand and agree that you are solely responsible for your
    actions and decisions to meet other people who you meet online by
    virtue of the services provided on goglt.com. You understand that
    goglt.com and Website Owner do not perform psychological testing or
    background checks on the individuals who may use the goglt.com
    services. You understand and agree that you must take all reasonable
    precautions before meeting others through the service provided by
    goglt.com, which includes, but is not limited to: exchanging e-mail
    first before giving out any personal information and meeting in a
    public place.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Do Not Rely on goglt.com.
    </span>
    Opinions, advice, statements or other comments should not
    necessarily be relied upon and are not to be construed as
    professional advice from goglt.com or Website Owner. goglt.com and
    Website Owner do not guarantee the accuracy or completeness of any
    of the information provided, and are not responsible for any loss
    resulting from your reliance on such information.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Warning re: Offensive Information.
    </span>
    While such action is strictly prohibited by any User, it remains
    possible that other goglt.com clients will post or send obscene or
    offensive materials on the site or to you via e-mail. It is also
    possible that by using your e-mail address, other people may,
    through other means outside the control of goglt.com, have access to
    personal information about you. goglt.com, Website Owner, its
    employees, agents, affiliates, licensees and web hosting services
    are not responsible for these transmissions or for the release of
    such information by others about you.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Right to Monitor.
    </span>
    goglt.com and Website Owner reserve the right, but are not
    obligated, to monitor materials posted in any public area and shall
    have the right to remove any information deemed offensive by our
    staff. Notwithstanding the foregoing, you remain solely responsible
    for your use of any information contained on the site. “You
    understand and agree that if your use of the website’s services is
    determined by goglt.com, in its sole and reasonable discretion, to
    be offensive, obscene or otherwise improper, goglt.com can terminate
    your use of the website immediately without prior notice and without
    any right of refund, set-off or a hearing.” You are responsible for
    any activities that take place under your username and password. If
    you become aware of any unauthorized use of your password or
    account, or any other breach of security, please contact goglt.com
    immediately. It is up to you to maintain the confidentiality of your
    password and account. goglt.com is not responsible or liable for any
    loss or damage arising from your failure to comply with the
    provisions of this Agreement.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Confidentiality.
    </span>
    No confidential information aside from payment information should be
    transmitted by you. The following information is deemed as not to be
    confidential: (1) basic biographical information and details
    relevant to your requested or offered services; (2) any information
    you actively chose to post; (3) messages to another client, which
    will disclose your e-mail address to that client or (4) any
    information related to an incident involving your harassment of
    another person via any service provided by goglt.com, in which case
    the confidentiality clause contained in this Agreement is rendered
    null and void. Any passwords used for this website are for the
    Client’s individual use only. You will be responsible for the
    security of your password(s). From time to time, goglt.com may
    require that you change your password. You are prohibited from using
    any services or facilities provided in connection with this website
    to compromise its security or tamper with any of its system,
    resources and/or accounts. The use or distribution of tools designed
    for compromising security (e.g., password crackers, rootkits, Trojan
    horses, or network probing tools) is strictly prohibited. If you
    become involved in any violation of system security, the goglt.com
    reserves the right to release your account details to system
    administrators at other websites and/or the authorities in order to
    assist them in resolving security incidents. Website reserves the
    right to investigate suspected violations of this Agreement. Website
    and Website Owner reserve the right to fully cooperate with any law
    enforcement authorities or court order requesting or directing
    goglt.com to disclose the identity of anyone posting any e-mail
    messages, or publishing or otherwise making available any materials
    that are believed to violate this Agreement. It is our policy to
    release a Client’s personal identifying information and any other
    information when we reasonably believe such disclosure is
    appropriate to comply with applicable law, to enforce any of our
    contracts or agreements, to protect the rights, property or safety
    of our users and customers, in response to a governmental authority
    request or legal process, or for purposes of fraud protection. By
    accepting this Agreement you waive all rights and agree to hold
    goglt.com and Website Owner harmless from any claims resulting from
    any action taken by goglt.com during or as a result of its
    investigations and/or from any actions taken as a consequence of
    investigations by either goglt.com or law enforcement authorities.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Ownership, Copyrights, Trademarks, Licenses.
    </span>
    goglt.com and Website Owner own and retain all proprietary rights to
    the goglt.com service, its trademarks and copyrights. You
    acknowledge and agree that the website and any software or programs
    used with respect to any shipwithglt’s services contain proprietary
    and confidential information that is the property of Website Owner
    [and its licensors] and is protected by applicable intellectual
    property and other laws. No rights or title of to any of the
    proprietary and confidential information on goglt.com or any
    software used in connection with any of its services is provided,
    transferred or assigned to you. You agree not to modify, rent,
    lease, loan, sell, distribute or create derivative works based on
    any of shipwithglt’s services or software, in whole or in part.
    Trademarks, service marks, logos, and copyrighted works appearing in
    goglt.com are the property of the Website Owner. goglt.com retains
    all rights with respect to any intellectual property appearing on
    the website, and no rights in such materials are transferred or
    assigned to you. Except for any information that is in the public
    domain, you are not authorized to reproduce, transmit or distribute
    the proprietary information of goglt.com and Website Owner. By
    posting information to goglt.com and Website Owner you represent
    that you have the right to grant permission for use by goglt.com and
    Website Owner.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      No Warranties.
    </span>
    goglt.com and Website Owner provide the goglt.com services on an "as
    is" basis and do not make any warranty, express, implied, limited or
    other with respect to the services provided. Specifically, goglt.com
    and Website Owner do not warrant that the service will always be
    available, be uninterrupted, be error free, meet your requirements,
    or that any defects in the services will be corrected. The use of
    goglt.com And any service it provides is at your sole risk. The
    website services are provided on an "as is" and "as available"
    basis. Goglt.com expressly disclaims all warranties of any kind,
    whether express or implied, including, but not limited to, the
    implied warranties of merchantability, fitness for a particular
    purpose, and non-infringement. Goglt.com makes no warranty that (1)
    the services will meet your requirements, (2) the services will be
    uninterrupted, timely, secure, or error-free, (3) the results of
    using the services will be accurate or reliable, (4) the quality of
    services will meet your expectations, or (5) that any errors in the
    software utilized by the services will be corrected.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold"> Arbitration. </span>
    Any dispute, controversy or claim arising out of or related in any
    manner to this Agreement for the use of the website, but not for
    actual transportation brokerage services, which cannot be amicably
    resolved by the parties shall be solely and finally settled by
    arbitration administered by the American Arbitration Association in
    accordance with its commercial rules and expedited rules/ timelines.
    Judgment on the award rendered by the arbitrator(s) may be entered
    in any court having jurisdiction thereof. The arbitration shall take
    place before a panel of one arbitrator sitting in Broward or Dade
    County Florida. The language of the arbitration shall be English.
    The arbitrators will be bound to adjudicate all disputes in
    accordance with federal law where applicable and otherwise with
    Florida law. The decision of the arbitrators shall be in writing
    with written findings of fact and shall be final and binding on the
    parties. The arbitrator shall be empowered to award money damages,
    subject to the limitations set forth in Section 1. of this
    Agreement, but shall not be empowered to award direct, indirect,
    incidental, special or consequential damages or specific
    performance. Each party shall bear its own costs relating to the
    arbitration proceedings irrespective of its outcome. This section
    provides the sole recourse for the settlement of any disputes
    arising out of, in connection with, or related to this Agreement.
    Notwithstanding the foregoing, any action seeking injunctive relief
    shall be submitted to the courts and shall not be subject to this
    provision.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold"> Jurisdiction. </span>
    Should section 10 not apply for any reason, any dispute between the
    parties to this Agreement shall be governed by Federal Law and where
    applicable the laws of Florida, without regard to provisions of
    conflicts of law. Any lawsuit arising from or related to this
    Agreement shall be brought exclusively before the United States
    District Court for the Southern District of Florida, or state courts
    in Broward County, Florida, and you hereby consent to the exclusive
    jurisdiction of any such court. The prevailing party in any such
    action shall be entitled to recover its reasonable attorneys' fees
    and costs incurred in litigating or otherwise settling or resolving
    such action. CLIENT FURTHER AGREES, TO THE EXTENT PERMITTED BY
    APPLICABLE LAW, TO WAIVE ANY RIGHT TO TRIAL BY JURY WITH RESPECT TO
    ANY CLAIM, COUNTERCLAIM OR ACTION ARISING FROM THE TERMS OF THIS
    AGREEMENT.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold"> Severability. </span>
    If any provision is found to be invalid, the remaining provisions
    will be in full force and effect. If any part of this Agreement is
    found by a court of competent jurisdiction to be unlawful, void or
    unenforceable, that part will be deemed severable and will not
    affect the validity and enforceability of any remaining provisions.
    In addition, in such event the unenforceable or invalid provision
    shall be deemed to be modified to the extent necessary to (i) render
    it valid and enforceable and (ii) give the fullest effect possible
    to the original intent of the provision.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Certification.
    </span>
    YOU MUST BE AT LEAST 18 YEARS OF AGE TO REGISTER, ACCESS AND USE ANY
    SERVICE PRVIDED BY THIS WEBSITE. IF YOU ARE UNDER THE AGE OF 18, DO
    NOT USE THIS WEBSITE FOR ANY PURPOSE. You certify that your answers
    to the registration materials on goglt.com will be truthful. In
    accessing and using goglt.com, you understand and agree that basic
    information concerning you, given to goglt.com, will be published on
    the web-site for others to view along with your User ID. Your name,
    address and e-mail address are kept confidential, except where
    provided above.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold">
      Entire Agreement.
    </span>
    This Agreement along with the Terms and Conditions of Brokerage
    Services constitute your entire Agreement with goglt.com and Website
    Owner with respect to any services. Those Agreements supersede all
    prior or contemporaneous communications and proposals, whether
    electronic, oral or written between you and goglt.com and Website
    Owner with respect to the goglt.com and any service it provides.
    Notwithstanding the foregoing, you may also be subject to additional
    terms and conditions, posted policies, guidelines, or rules that may
    apply when you use goglt.com or any of its services. Website Owner
    may revise these Terms and Conditions of Use at any time, and such
    changes will be effective from and after the date that the same are
    delivered to you by e-mail or regular mail. Your continued use of
    goglt.com after any changes have been made to this Agreement
    signifies and confirms your acceptance of any changes or amendments
    to this Agreement.
  </p>
</li>
<li>
  <p>
    <span style="font-weight: bold"> Waiver. </span>
    The failure of Website or Website Owner to exercise or enforce any
    right or provision of this Agreement shall not operate as a waiver
    of such right or provision. Any waiver of this Agreement by Website
    or Website Owner must be in writing and signed by an authorized
    representative of the Website Owner.
  </p>
</li>
</ol>
<p>
You further understand and agree that clicking or pressing on the "I
agree" is the electronic equivalent of a written signature on this
document.
</p>`;

export default termsAndConditions;
