<template>
  <div class="terms-conditions-card">
    <div class="terms-conditions-card__header">
      <button @click="$emit('closeModalTerms')" class="close-button">
        <i class="ion ion-close-circle-outline close-button__icon"></i>
      </button>
    </div>
    <h5 class="terms-conditions-card__title">{{$t("createAccount.termsAndConditions")}}</h5>
    <div
      class="terms-conditions-card__content"
      v-html="termsAndConditions"
    ></div>
    <div class="terms-conditions-card__buttons">
      <button
        class="terms-conditions-card__button-decline"
        @click="declineTerms"
      > {{$t("createAccount.decline")}}
      </button>
      <button class="terms-conditions-card__button-accept" @click="acceptTerms">
        {{$t("createAccount.accept")}}
      </button>
    </div>
  </div>
</template>

<script>
import TermsAndConditions from "../../utils/TermsAndConditions";

export default {
  name: "TermsAndConditions",
  emits: ["closeModalTerms", "acceptTerms", "declineTerms"],
  data() {
    return {
      termsAndConditions: TermsAndConditions,
    };
  },
  methods: {
    declineTerms() {
      this.$emit("closeModalTerms");
      this.$emit("declineTerms");
    },
    acceptTerms() {
      this.$emit("closeModalTerms");
      this.$emit("acceptTerms");
    },
  },
};
</script>

<style scoped lang="scss">
.terms-conditions-card {
  font-family: $font-family-portal;
  width: 100%;
  padding-top: 4%;
  @extend %flex-col-align;
  &__header {
    width: 100%;
    display: flex;
    @extend %flex-end-center;
    margin-bottom: 2%;
  }
  &__content {
    overflow-y: scroll;
    width: 80%;
    height: 60vh;
    @include font-small-information;
    letter-spacing: 0px;
    text-align: left;
    @extend %flex-col-start-center;
  }
  &__title {
    @include font-section-title;
    letter-spacing: 0px;
    color: $color-primary-company;
    font-weight: bold;
    margin-bottom: 3%;
  }
  &__buttons {
    width: 100%;
    @extend %flex-around-center;
    margin: 5% 0px;
    @include font-button-text;
  }
  &__button-decline,
  &__button-accept {
    @include primary-button;
    height:35px;
    width:40%;
  }
}
.close-button {
  position: absolute;
  top: 0;
  right: 0px;
  background: none;
  border: none;
  color: $color-primary-company;
  &__icon {
    font-size: 35px;
  }
}
::-webkit-scrollbar {
  width: 7px;
}
::-webkit-scrollbar-track {
  background-color: inherit;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #dadada;
  border-radius: 10px;
}
@media (max-width: 475px) {
  .terms-conditions-card {
    &__buttons {
      display: flex;
      flex-direction: column;
    }
    &__button-decline,
    &__button-accept {
      margin-top: 10px;
    }
  }
}
</style>
